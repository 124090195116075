.data-grid-container .data-grid .cell {
	padding: 8px;
	background-color: #efefef;
  color: #3F4254;
	cursor: text;
	}

.data-grid-container .data-grid .cell.read-only {
    background-color: #fff;
    color: #3F4254;
		cursor: default;
}

.data-grid-container .data-grid .cell .value-viewer, 
.data-grid-container .data-grid .cell .data-editor {
    background-color: #fff;
		border: 1px solid #999;
		padding-left: 3px;
		padding-right: 3px;
}

.data-grid-container .data-grid .cell:not(.read-only) .value-viewer {
	width: 80px;
	display: inline-block;
  }

.data-grid-container .data-grid .cell.read-only .value-viewer, 
.data-grid-container .data-grid .cell.read-only .data-editor {
		border: 1px solid #fff;
}

.data-grid-container .data-grid .cell > input {
    outline: none !important;
    border: none;
    height: auto;
    display: inline-block;
		width: 80px;
}
.data-grid-container .data-grid .cell.thcell{
	font-weight: bold;
}
.data-grid-container .data-grid .cell.hidden{
	height: 0.1px;
}
.data-grid-container .data-grid .cifra {
    text-align: right !important;
}
.data-grid-container .data-grid .red {
    color: red !important;
	font-weight: bold;
}

.data-grid-container .data-grid .cell .data-editor {
    display: inline-block;
}

.data-grid-container .data-grid .cell.empty_month {
    background-color: #666;
}
.data-grid-container .data-grid .cell.empty_month > span{
    background-color: #666;
	width: 0px;
}